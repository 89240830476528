const paramFilter = (p) => p !== undefined && p !== '';

export const generateQueryString = (params) => {
  let queryString = (params || []).filter(paramFilter).join('&');
  queryString = (queryString.length > 0) ? `?${queryString}` : '';
  return queryString;
};

export default {
  generateQueryString,
};
