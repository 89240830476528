<template>
  <div class="tmc-base-multi-item-select">
    <v-col>
      <v-select
        :class="getClassName"
        v-model="selectedItems"
        :items="itemList"
        :item-text="itemText"
        :item-value="itemValue"
        :label="label"
        multiple
      >
        <template v-slot:selection="{ item, index }"
          >
          <v-chip v-if="index === 0">
            <span>{{ item[`${itemText}`] }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="grey--text text-caption"
          >
            (+{{ selectedItems.length - 1 }} others)
          </span>
        </template>
        <template v-slot:prepend-item
          >
          <v-list-item
            ripple
            @click="toggle"
          >
            <v-list-item-action>
              <v-icon :color="selectedItems.length > 0 ? 'indigo darken-4' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Select All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </v-col>
  </div>
</template>

<script>
import {
  mdiSelectAll,
  mdiCloseBox,
  mdiCheckboxBlankOutline,
  mdiMinusBox,
} from '@mdi/js';

export default {
  name: 'MultiItemSelectList',
  data: () => ({
    icons: {
      mdiSelectAll,
      mdiCloseBox,
      mdiCheckboxBlankOutline,
      mdiMinusBox,
    },
  }),
  props: {
    label: {
      type: String,
      default: () => 'By Select',
    },
    itemText: {
      type: String,
      default: () => 'caption',
    },
    itemValue: {
      type: String,
      default: () => '_id',
    },
    itemList: {
      type: Array,
      default: () => ([]),
    },
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    selectedItems: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit('selected-items', value);
      },
    },
    isAllItemSelected() {
      if (this.itemList.length > 0) {
        return this.selectedItems.length === this.itemList.length;
      }
      return false;
    },
    isSomeItemSelected() {
      return this.selectedItems.length > 0 && !this.isAllItemSelected;
    },
    icon() {
      if (this.isAllItemSelected) return this.icons.mdiCloseBox;
      if (this.isSomeItemSelected) return this.icons.mdiMinusBox;
      return this.icons.mdiCheckboxBlankOutline;
    },
    getClassName() {
      return `${this.itemText}-multi-item-select`;
    },
  },
  methods: {
    selectAllItems() {
      this.selectedItems = [...this.itemList.map((item) => item[`${this.itemValue}`])];
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isAllItemSelected) {
          this.selectedItems = [];
        } else {
          this.selectAllItems();
        }
      });
    },
  },
};
</script>
